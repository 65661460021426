//.panel-bottom
//  .panel-content
//    padding: 0 15px



.panel-bottom-controls
  position: fixed
  bottom: 45px
  z-index: 300
  left: 20px
  background: rgba(0, 0, 0, 0.75)
  padding: 5px 10px
  border-radius: 4px

  .close-btn
    @extend .fa
    @extend .fa-angle-double-down

  &.closed
    .close-btn
      @extend .fa-angle-double-up

  span
    margin-left: 10px
    font-size: 14px
    vertical-align: middle


  i
    font-size: 19px !important

    &:hover
      cursor: pointer



// LEFT -RIGHT arrows to navigate the book
.nav-buttons
  position: fixed
  width: 223px
  bottom: 80px
  margin-left: -112px
  z-index: 100
  left: 50%
  a
    outline: none
    position: absolute
    border: none
    z-index: 100
    text-shadow: 0 1px 2px rgba(0,0,0,.6)
    background-color: rgba(0, 0, 0, 0.75)
    color: #ffffff
    cursor: pointer
    padding: 4px 8px 2px 8px
    border-radius: 5px
    &.button-left
      left: 0
    &.button-right
      right: 0


