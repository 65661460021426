#panel-top
  i
    @extend .fa
    font-size: 20px
    &.ubu-share
      @extend .fa-share-alt
  ul
    position: fixed
    top: 0
    left: 50%
    z-index: 50
    background: $panelBackground
    padding: 3px 3px
    border-radius: 5px
    margin-left: -112px
    margin-top: 10px
    li
      list-style: none
      padding: 1px 5px
      float: left
      width: 45px
      button
        outline: none
        cursor: pointer
        background: none
        border: none
        color: #fff
        &.fullscreen
          i
            @extend .fa-expand
          &.full
            i
              @extend .fa-compress
      &:hover
        color: #ffffff



.ubu-share-panel
  text-align: center
  margin: 50px auto
  button
    margin: 0.5em

