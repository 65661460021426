.transcriptions
  span
    outline: none

.main.annotations
  .coordinates
    cursor: pointer
    //text-decoration: underline
    border-bottom: 1px dotted #D20707
    outline: none
    &:hover,
    &.coordinates-selected
      color: #D20707
      text-decoration: none
.transcriptions
  font-size: 16px

.transcriptions.vscroll
  top: 125px

