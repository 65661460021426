
.thumb-select
  #thumb-wrapper
    position: absolute
    z-index: 1
    top: 5px
    bottom: 0
    //left: 50%
    width: 100%
    overflow: visible
    height: $panelBottomHeight



  #thumb-select
    margin-top: 5px
    position: absolute

    z-index: 1
    height: 100%
    transform: translateZ(0)
    user-select: none
    text-size-adjust: none
    ul
      list-style: none
      padding: 0
      margin: 0
      width: 100%
      height: 100%
      text-align: center
    li
      width: 90px
      height: 109px
      display: block
      float: left
      font-size: 14px
      cursor: pointer
      position: relative
      img
        color: #000000
      a
        outline: none
      span
        font-size: 22px
        color: black
        position: absolute
        padding-top: 10px
        left: 0
        width: 83px
        margin: 0 3px
        height: 100%
        border-color: #000000
        border-width: 2px
        border-style: solid
      &:hover
        span
          border-color: #cccccc
      &.tb-active
        span
          border-color: #ffffff


