//iscroll

#thumb-wrapper
  position: absolute
  z-index: 1
  top: 5px
  bottom: 0
  left: 0
  width: 100%
  overflow: hidden
  height: 125px



#thumb-scroller
  margin-top: 5px
  position: absolute
  z-index: 1
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  height: 100%
  -webkit-transform: translateZ(0)
  -moz-transform: translateZ(0)
  -ms-transform: translateZ(0)
  -o-transform: translateZ(0)
  transform: translateZ(0)
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-text-size-adjust: none
  -moz-text-size-adjust: none
  -ms-text-size-adjust: none
  -o-text-size-adjust: none
  text-size-adjust: none


#thumb-scroller ul
  list-style: none
  padding: 0
  margin: 0
  width: 100%
  height: 100%
  text-align: center


#thumb-scroller li
  width: 90px
  height: 109px
  display: block
  float: left
  font-size: 14px
  cursor: pointer
  position: relative
  span
    font-weight: bold
    font-size: 18px
    color: black
    position: absolute
    padding-top: 10px
    left: 0
    width: 83px
    margin: 0 3px
    height: 100%
    border-color: #000000
    border-width: 3px 0
    border-style: solid
  &.tb-active
    span
      border-color: $redColor

.iScrollHorizontalScrollbar
  position: absolute
  z-index: 9999
  height: 7px
  left: 2px
  right: 2px
  bottom: 0px
  overflow: hidden

.iScrollIndicator
  box-sizing: border-box
  position: absolute
  border: 1px solid $redColor
  height: 100%
  display: block
  background: rgba(0, 0, 0, 0.498039)



