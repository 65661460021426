#cover-unesco {
  position: absolute;
  width: 160px;
  top: 70px;
  left: 23px;
  z-index: 100000;
  @media (max-width: 800px) {
    display: inline-block;
    margin-left: -80px;
    left: auto;
    top: 110px;
  }
}


#cover {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 99999;

  #cover-title {
    top: 15px;
    position: absolute;
    width: 100%;
    z-index: 3;
    color: #ffffff;
  }

  #cover-image {
    background: #000 url(../images/cover.jpg) no-repeat center center;
    //-webkit-background-size: contain;
    //-moz-background-size: contain;
    //-o-background-size: contain;
    background-size: contain;
    position: absolute;
    top: 60px;
    bottom: 100px;
    left: 0;
    right: 0;
  }

  #cover-message {
    width: 100%;
    bottom: 0;
    position: absolute;
    margin-bottom: 50px;

    p {
      margin: auto;
      max-width: 550px;
      font-size: 1em;
      color: #EEEEEE;
      z-index: 3;
    }
  }

  #cover-text {
    position: absolute;
    top: 50px;
    bottom: 50px;
    width: 100%;
    //background-color: rgba(0,0,0,0.7);
    //padding: 10px;
    text-shadow: 2px 2px #000000;
  }




  //.cover-image {
  //  background: url(/images/cover.jpg) no-repeat center center fixed;
  //  position: absolute;
  //  right: 60px;
  //  left: 60px;
  //  top: 160px;
  //  bottom: 60px;
  //}
}
