// FA
$fa-font-path: "../webfonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Media-queries
@import "media";

// Custom
$headerHeight: 50px;
$panelRightWidth: 350px;
$panelRightWidth-sm: 300px;
$panelBottomHeight: 127px;
$panelBackground: rgba(0, 0, 0, 0.75);
$panelRightBackground: #ffffff;
$panelRightColor: #000000;
$panelControlsHeight: 30px;

$thumb-navigation-height: 125px;

$textColor: #dadada;
$redColor: #c60000;


@import "controls";
@import "thumb-scroll";
@import "thumb-select";
@import "Intro";
@import "Layout";
@import "PanelRight";
@import "PanelBottom";
@import "PanelTop";
@import "transcriptions";
@import "Viewer";
//@import "share";
@import "modal";
@import "ButtonSearch";
@import "ModalDownloadImage";
@import "ModalSearch";
@import "StaticContent";




/* Space out content a bit */
html {
  background-color: #000;
}

body {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  // prevent touch-action capturing by evil mobile browsers (IE)
  touch-action:none;
  -ms-touch-action:none;
  background-color: inherit;
}


span, p {
  font-size: 14px;
  line-height: 1.5em;
}



//.main {
//  touch-action: auto;
//  -ms-touch-action: auto;
//  position: fixed;
//  top: 53px;
//  bottom: 0;
//  right: 0;
//  left: 0;
//  z-index: 1;
//  background-color: #000000;
//}
//

.uu-logo {
  padding-left: 0;
  padding-top: 5px;
  position: fixed;
  top: 0;
  left: 20px;
  z-index: 200;
  h1 {
    color: white;
    font-size: 1.4em;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

#app-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    color: #808080;
  }

  a {
    color: #ffffff;
  }
}

div#not-found {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../images/not-found.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .not-found-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    padding:3em;
    width: 50%;
    color: #808080;
    max-width: 700px;
    .page {
      color: #ffffff;
    }
    h2 {
      color: #ffffff
    }

    a {
      color: #ffffff;
    }
  }
}



