$controlBackground: rgba(39, 39, 39, 0.5)

// Make line breaks visible
.new-line
  white-space: pre-line
  display: inline

.highlightedText
  color: red

.panel-right
  //z-index: 21
  //width: $panelRightWidth
  //right: 0
  //top: 0
  ////margin: 0 20px 20px 0
  //-webkit-backface-visibility: hidden



  //@include phone {
  //  width: $panelRightWidth-sm
  //}
  //
  //@include tablet {
  //  width: $panelRightWidth-sm
  //}

  .panel-content
    padding: 0

  .close-btn
    position: absolute
    @extend .fas
    @extend .fa-angle-double-right
    @extend .fa-2x
    top: 43px
    right: $panelRightWidth
    cursor: pointer
    padding: 6px 10px

  &.closed

    .close-btn
      @extend .fas
      @extend .fa-angle-double-left
      //right: 0


  .coordinates
    cursor: pointer
    //text-decoration: underline
    border-bottom: 1px dotted #D20707
    outline: none
    &:hover,
    &.coordinates-selected
      color: #D20707
      text-decoration: none

  /* Top Psalm title */
  ul.section li
    font-size: 1.2em
    background-color: transparent !important
    button
      background-color: transparent !important
      min-height: 2.3em


  // TABS
  .nav-tabs
    border: none
  .nav-link
    border: none
    cursor: pointer
    border-radius: unset

  //  Section tabs
  .section
    .nav-link
      color: #808080
      &.active
        color: #ffffff



  // Transcriptions , annotation, addInfo TABS
  .tab-content
    .nav-item
      max-width: 33.33%
    .nav-link
      color: #ffffff
      &.active
        color: $panelRightColor
      i
        font-size: 1.5em

  // Transcription content
  .transcriptions
    padding-top: 8px
    // Language switch
    .nav-link
      font-size: 0.7em
      margin: 0
      &.active
        text-transform: uppercase
        font-weight: bold
        color: $panelRightColor






div.panel-right.closed div.page-details
  li
    a
      background-color: rgba(39, 39, 39, 0.65)
      color: #ffffff


.vscroll
  overflow: auto
  -webkit-overflow-scrolling: touch
  padding: 10px
  top: 95px
  bottom: 0
  left: 0
  right: 0
  position: absolute
  background-color: $panelRightBackground
  color: $panelRightColor
  ul
    padding-left: 18px

p.vscroll
  margin-bottom: 0px
