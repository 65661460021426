
#viewer
  position: absolute
  top: 0
  left: 0
  margin: 0
  padding: 0
  .annotation
    border: 1px solid #000000
    &.annotation-selected
      border: 2px solid #ffffff

// .annotations are shown only when the bar left is open, it mean when .main has class .panel-right
.main
  #viewer
    //right: 0
    .annotation
      display: none
  &.with-panel-right.annotations
    #viewer
      .annotation
        display: block
        cursor: pointer



// TODO fix phone and tablet
#viewer-nav
  position: absolute
  top: 80px
  left: 10px
  width: 150px
  .loadBar
    line-height: 10px
    width: 100%

  //@include phone
  //  width: 100px
  //  .navcontainer .navbuttons img
  //    width: 26px
  //    height: 26px
  //@include tablet
  //  width: 100px
  //  .navcontainer .navbuttons img
  //    width: 26px
  //    height: 26px


// hide iipviewer logo
img.logo
  display: none


.zoom-buttons
  z-index: 2
  position: absolute
  left: 20px
  top: 100px
  cursor: pointer
  i
    background-color: rgba(0,0,0,0.75)
    padding: 5px 7px
    display: block
    border-radius: 5px
    margin: 3px
    font-size: 1.5em

