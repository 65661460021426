/**
This file controls the layout fo the app
- opening and closing panels
- panels position and sizes

 */
.panel-content {
  position: relative;
  height: 100%;
}

#viewer {
  bottom: 0;
  right: 0;
  transition: 1s;
}


.panel {
  position: fixed;
  z-index: 20;
}

/**
 Panel Right
 */
.panel-right {
  z-index: 21;
  right: -350px;
  top: 0;
  -webkit-backface-visibility: hidden;
  width: $panelRightWidth;
  bottom: 0;
  transition: 1s;


  //@include phone {
  //  width: $panelRightWidth-sm;
  //}
  //
  //@include tablet {
  //  width: $panelRightWidth-sm;
  //}

  .panel-content {
    padding: 0;
  }

}

/**
  Panel Bottom
 */
.panel-bottom {
  background-color: $panelBackground;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 1s;
}



.main {

  &.with-panel-bottom {
    bottom: $panelBottomHeight;

    .panel-right {
      bottom: $panelBottomHeight;
    }

    .panel-bottom {
      height: $panelBottomHeight;
      transition: 1s;
    }

    #viewer {
      bottom: $panelBottomHeight;
    }

    //&.with-panel-right {
    //  .panel-right {
    //    bottom: $panelBottomHeight;
    //  }
    //}
  }

  &.with-panel-right {
    right: $panelRightWidth;

    //resize panel right when panel-bottom is hidden
    .panel-right {
      right: 0;
      transition: 1s;
    }

    #viewer {
      right: $panelRightWidth;
    }

    //@include phone {
    //  right: $panelRightWidth-sm;
    //}
    //
    //@include tablet {
    //  right: $panelRightWidth-sm;
    //}
  }
}
