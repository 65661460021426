.modal-backdrop
  bottom: 0


.modal-dialog
  color: black

.info-text
  h4
    padding: 1em 0 0.3em 0
    font-weight: bold

  .nav-tabs
    padding-left: 10px
    li a
      background-color: inherit
      color: #000000
      font-weight: bold
    li.active a
      font-weight: normal
      border-bottom-color: #ffffff
      background-color: inherit
      color: #2e2e2e

.info-title
  .fa
    font-size: 1.2em
    background-color: black
    color: white
    padding: 5px
  strong
    margin-left: 8px
  &.nav
    .fa
      font-size: 1em

.fa-bw
  background-color: black
  color: white
  padding: 5px

.modal-content
  a:hover, a:focus
    color: #333


#searchText
  width: 350px

#search-results
  margin-top: 15px
  i
    background-color: black
    color: white
    padding: 4px
    position: absolute
    left: 15px
    font-size: 1em
  p
    padding-left: 30px



