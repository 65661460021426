.info-icon {
  color: #fff;
  background-color: #000;
  display:inline;
  padding: 5px;
}


.info-nav-link {
  .nav-link {
    background-color: rgba(203, 203, 203, 0.99);
    color: #888;
  }
}
