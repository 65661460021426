
.tooltip-inner
  background-color: #545454


.panel-control
  background: $panelBackground
  border-radius: 4px




